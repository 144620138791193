import React from "react";
import {insecureGet} from "../service/getDataService";
import {Image} from "react-bootstrap";
import "../assets/style/shows.css"

export function Shows(content){
    return (
        <section id="shows" className="shows section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>{content.title}</h2>
                    <p>{content.subtitle}</p>
                </div>
                <div className="row d-flex align-content-center">
                    {
                        content.rows.map(row => {
                            return (
                                <div className="col-lg-12 mt-4">
                                    <div className='row show d-flex align-content-center'>
                                        <div className=" col-lg-3">
                                            <div className="pic">
                                                <Image
                                                    src={row.imgurl}
                                                    className="img-fluid shows-img"
                                                    alt={row.imgalt}/>
                                            </div>
                                        </div>
                                        <div className=" col-lg-8 show-info">
                                            <h4>{row.title}</h4>
                                            <span>{row.subtitle}</span>
                                            <p>{row.summary}</p>
                                        </div>
                                        <div className=" col-lg-6">
                                                <iframe width="560" height="315" style={{borderRadius:"25px",maxWidth:"100%"}}
                                                        src="https://www.youtube.com/embed/GftJ12r4Hss?si=LWhkt68brf7aqk1e"
                                                        title="YouTube video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        referrerPolicy="strict-origin-when-cross-origin"
                                                        allowFullScreen></iframe>
                                        </div>
                                        <div className=" col-lg-6">
                                            <iframe width="560" height="315" style={{borderRadius:"25px",maxWidth:"100%"}}
                                                    src="https://www.youtube.com/embed/ORvXLXgTHfY?si=pm7hLZxCMVY34oqW"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen></iframe>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}