import React from "react";
import { Hero } from "../components/hero";
import { About } from "../components/about";
import { Job } from "../components/job";
import { Team } from "../components/team";
import { Contact } from "../components/contact";
import { Cta } from "../components/cta";
import { Gallery } from "../components/gallery";
import Header from "../components/header";
import { Footer } from "../components/footer";
import { Shows } from "../components/shows";
export default function Home() {
    const header = {
        id: 0,
        label: 'header',
        url: 'img/logomarion.png',
        alt: "logo",
        lis: [
            { id: 1, href: "#about", item: "À propos de nous" },
            { id: 2, href: "#job", item: "Agenda" },
            { id: 3, href: "#shows", item: "À l'affiche" },
            { id: 4, href: "#team", item: "La direction artistique" },
            { id: 5, href: "#gallery", item: "Galerie" },
            { id: 6, href: "#contact", item: "Contact" }
        ]
    }

    const hero = {
        id: 1,
        label: 'hero',
        title: 'SAISON DE MUSIQUE DE CHAMBRE',
        subtitle: "église Saint-Pierre et Saint-Paul, marseille",
        date: ' - 2024/2025 - ',
        btn1url: "https://www.youtube.com/watch?v=JTN34X_tdXs",
        btn1value: "Visionner le teaser de présentation de la saison",
        btn2url: "img/Saison-2024-2025-Arts-Phocéens.pdf",
        btn2value: "Télécharger notre brochure",
        backgroundurl: "/img/accueil.png",
        colortitle: "#df9424",
        colorsubtitle: "#aaa8a6",
        fontsizetitle: "58px",
        fontsizesubtitle: "34px",
    }
    const about = {
        id: 3,
        label: 'about',
        title: "Les Arts Phocéens",
        lis:
            [
                { content: "Créée par Benoît Zahra en 2021,  l'association Les Arts Phocéens défend une vision décloisonnée de la musique classique en proposant des formats de concerts innovants et ludiques, alliés à une exigence artistique dont témoigne l’excellence de ses interprètes." },
                { content: "Les concerts des “Arts Pho’” font en effet la part belle à la médiation, repensent les codes des interactions entre musiciens et public, le tout dans un esprit chaleureux teinté d’humour. Telle est la signature de ce collectif dont la devise, “faire du classique autrement”, prend alors tout son sens.\n" },
                { content: "L’association est gérée par une équipe de jeunes musiciens en début de carrière (Benoît Zahra et Marion Rougon-Betis à la direction artistique, Jeanne Maugrenier, qui vient d’intégrer l’Opéra de Paris comme corniste, à la présidence de l’association…). Dans cette optique, la programmation des Arts Phocéens se veut à la fois un terrain d’expression artistique et un véritable tremplin pour les jeunes interprètes prometteurs qu’elle a à cœur de faire découvrir." }
            ],
        colortext: "#000000",
        colortitle: "#37517e",
        coloricon: "#37517e"
    }
    const cta = {
        id: 4,
        label: 'cta',
        title: "La direction artistique",
        // subtitle:"Une union soudée",
        imgUrl: "/",
        content: "content",
        color1: "#FFFFFF",
        color2: "#000000",
        ctaValue: "cta"
    }
    const team = {
        id: 5,
        label: 'team',
        rows:
            [
                {
                    imgurl: "img/team/benoit2.jpg", imgalt: "alt", name: "Benoît Zahra", job: "Directeur artistique & violoncelliste", bio: "Né en 1994 à Marseille, Benoît Zahra y obtient à 18 ans un Diplôme d'Etudes Musicales, au Conservatoire à Rayonnement Régional. Il poursuit ses études à l’Ecole Normale de musique de Paris Alfred Cortot  où il obtiendra en 2018 son Diplôme Supérieur de Concertiste à l’unanimité du jury. Il obtient également en 2018 son Diplôme national de musicien professionnel ainsi que son diplôme d’état de professeur au Pôle Supérieur de Paris-Boulogne-Billancourt. Il intègre l’Orchestre Français des Jeunes avec lequel il se produit lors des saisons 2017 et 2018. Il est admis à l’académie de l’Orchestre de Paris en 2018, ainsi qu’à l’orchestre du Schleswig Holstein Musik Festival en Allemagne, avec lequel il se produit pour une quarantaine de concerts, notamment en tant que violoncelle solo sous la direction de chefs prestigieux.\n" +
                        "Son parcours l’amène à se produire en récital à la Philharmonie de Paris et salle Cortot, ainsi qu’au sein de plusieurs orchestres professionnels, à l’Opéra de Marseille, Toulon, Cannes, Avignon, L’Orchestre National des pays de la Loire, l’opéra royal de Versailles, l'orchestre de Paris.\n" +
                        "Parallèlement à ses études musicales, Benoît Zahra fait partie de nombreux projets associatifs visant à organiser et vendre des concerts. Il a été directeur artistique et fondateur de l'ensemble Oct'opus, avec lequel il vendra et organisera trois tournées entre 2016 et 2019. C'est en 2021, qu'il crée l'association les Arts Phocéens, avec laquelle il a notamment produit plusieurs concerts pour la ville de Bollène"
                },
                {
                    imgurl: "img/team/marion.png", imgalt: "alt", name: "Marion Rougon-Betis", job: "co-directrice artistique & flûtiste", bio: "Marion Rougon-Betis comprend que la musique est sa vocation à l’école de musique de Saint-Rémy de Provence, où elle commence la flûte traversière. Elle entre ensuite au Conservatoire d’Avignon puis au Conservatoire à Rayonnement Régional de Paris, avant de terminer ses études à la Haute Ecole de Musique de Genève, où elle décrochera brillamment son Master en 2023.\n" +
                        "Son parcours est jalonné de participations à de prestigieux orchestres de jeunes, ainsi que d’invitations à se produire dans des phalanges telles que l’orchestre de Paris, la Radio Suédoise, ou l’orchestre de la Suisse Romande.\n" +
                        "Musicienne au parcours diversifié, Marion Rougon-Betis est également titulaire d’un Master de Management des Organisations Culturelles de l’Université Paris Dauphine.\n" +
                        "Elle rencontre Benoît Zahra au sein du Schleswig-Holstein Musik Festival. La profonde amitié qui les lie débouche sur une collaboration professionnelle enthousiaste, qui amène Benoît Zahra à intégrer Marion Rougon-Betis à la direction artistique des Arts Phocéens en 2023.\n"
                }
            ]
    }
    const gallery = {
        id: 6,
        label: 'gallery',
        title: "Galerie",
        content: "Cliquez sur les photos pour les agrandir",
        rows:
            [
                { imgurl: "img/team/marion.jpg", imgalt: "alt", title: "un homme", legende: "blabla" },
                { imgurl: "img/081124-2.jpg", imgalt: "alt", title: "deux homme", legende: "blabla" },
                { imgurl: "img/hero1.jpg", imgalt: "alt", title: "trois homme", legende: "blabla" },
                { imgurl: "img/team/benoit.jpg", imgalt: "alt", title: "quatre homme", legende: "blabla" },
                { imgurl: "img/081124.jpg", imgalt: "alt", title: "cinq homme", legende: "blabla" },
                { imgurl: "img/cta.jpg", imgalt: "alt", title: "six homme", legende: "blabla" },
            ]
    }
    const job = {
        id: 7,
        label: 'job',
        title: "Agenda",
        subtitle: "Evénements à venir :",
        rows:
            [
                { date: "25.10.2024", hour: "", place: " Azincourt, l'église", title: "The Battle of Azincourt" },
                { date: "11.01.2025", hour: "", place: " Marseille, église Saint-Pierre et Saint-Paul", title: "Un soir àl'opera" },
                { date: "29.03.2025", hour: "", place: " Marseille, église Saint-Pierre et Saint-Paul", title: "Cellomania les plus grandes figures du violoncelle" },
                { date: "29.03.2024", hour: "", place: " Arles et Saint Martin de Crau", title: "Magellan, ou le premier tour du monde" },
                { date: "30.03.2024", hour: "", place: " Arles et Saint Martin de Crau", title: "Magellan, ou le premier tour du monde" },
            ],
        subtitle2: "Evénements passés : ",
        rows2:
            [
                { date: "07.01.2024", hour: "", place: " Bollène, La Cigalière", title: "Magellan, Le premier tour du Monde" },
                { date: "06.01.2024", hour: "", place: " Marseille, église Saint-Pierre et Saint-Paul", title: "Le premier tour du Monde (duo flûte-violoncelle)" },
                { date: "08.01.2023", hour: "", place: "Bollène, La Cigalière", title: "Les cordes romantiques" },
                { date: "07.01.2023", hour: "", place: "Marseille, église Saint-Pierre et Saint-Paul", title: "Les cordes romantiques (sextuor à cordes)" },
                { date: "09.01.2022", hour: "", place: " Bollène, La Cigalière", title: "Une Vienne intimiste" },
                { date: "08.01.2022", hour: "", place: " Marseille, église Saint-Pierre et Saint-Paul", title: "Une Vienne intimiste (quatuor avec flûte)" },
            ]
    }
    const contact = {
        id: 10,
        label: 'contact',
        title: 'Contact',
        subtitle: 'Pour pouvoir communiquer avec nous directement',
        titleaddress: "Adresse : ",
        address: '2 allée Simone Gebelin 13001 Marseille',
        titleemail: 'Email : ',
        email: 'contact@lesartsphoseens.fr',
        titlephone: 'Téléphone : ',
        phone: '0635260496',
        namelabel: 'Votre nom : ',
        nameplaceholder: 'votre nom ici',
        emaillabel: 'Votre email : ',
        emailplaceholder: 'Pour pouvoir vous recontacter',
        subjectlabel: 'L\'objet de votre message : ',
        subjectplaceholder: 'objet',
        messagelabel: 'Votre message',
        messageplaceholder: '',
        errormessage: 'error',
        correctmessage: 'correct',
        btnvalue: 'Envoyer'
    }
    const footer = {
        id: 11,
        label: 'footer',
        copyright: 'Les Arts Phocéens'
    }
    const shows = {
        id: 12,
        label: 'shows',
        title: "À l'affiche",
        subtitle: "Nos spectacles",
        rows: [
            {
                imgurl: "/img/cta.jpg",
                imgalt: "spectacle1",
                title: "Magellan - Le premier tour du Monde",
                summary: "Un voyage musical retraçant le périple du célèbre navigateur portugais à travers des œuvres de Marin Marais, Vivaldi, Villa-Lobos, Piazzolla et bien d’autres compositeurs, porté par un duo flûte-violoncelle."
            },
        ]
    }
    return (
        <>
            {
                Header(header)
            }{
                Hero(hero)
            }{
                About(about, about.lis)
            }{
                Job(job)
            }{
                Shows(shows)
            }
            {
                Cta(cta)
            }{
                Team(team)
            }{
                Gallery(gallery)
            }{
                // Faq(faq)
            }{
                Contact(contact)
            }{
                Footer(footer)
            }
        </>)
}