import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { JWT_KEY, USER_NAME, setLocalStorage } from '../../service/localStorageService'
// import { notifyToast } from '../../service/notifyService'
//import { insecurePost, postLogin, postRecoverPassword } from '../../service/postDataService'
//import { ERROR, ERROR500, ERRORLOG601, ERRORLOG602, ERRORMDPLOST601, Log1, Log2, Log3, Log4, Rec200, So11, So13, So14, So15, So3, SUCCESSMDPLOST, Header8 } from '../stringfr'
// import Toast from './toast'

export default function Login() {

    // const [form, setform] = useState({ mail: "", password: "" })
    //
    // const [secondform, setsecondform] = useState({ mail: "" })
    //
    //
    // const navigate = useNavigate()
    //
    // const handleChange = (event) => {
    //     const key = event.target.name
    //     const value = event.target.value
    //     setform({ ...form, [key]: value })
    // }
    //
    // const evtnavigate = (event) => {
    //     navigate('/signon')
    // }
    //
    // const handleSecondChange = (event) => {
    //     const key = event.target.name
    //     const value = event.target.value
    //     setsecondform({ ...secondform, [key]: value })
    // }
    // const handleSubmit = async (event) => {
    //     event.preventDefault()
    //     const data = {
    //         mail: form.mail,
    //         password: form.password
    //     }
    //     insecurePost('Login', JSON.stringify(data)).then((result) => {
    //         if (result.status === 200) {
    //             setLocalStorage(USER_NAME, result.data.user.firstname + " " + result.data.user.lastname)
    //             setLocalStorage(JWT_KEY, result.data.token).then(() => {
    //                 navigate('/')
    //             })
    //         }
    //         else {
    //             if(result.status ===601)
    //             {
    //                 notifyToast(ERRORLOG601)
    //             }
    //             else if(result.status === 602)
    //             {
    //                 notifyToast(ERRORLOG602)
    //             }
    //             else{
    //                 notifyToast(ERROR500)
    //             }
    //         }
    //     })
    //
    // }
    // const handleSecondSubmit = async (event) => {
    //     event.preventDefault()
    //     const data = {
    //         mail: secondform.mail
    //     }
    //     insecurePost('Recover', JSON.stringify(data)).then((result) => {
    //         if (result.status === 200) {
    //             notifyToast(SUCCESSMDPLOST)
    //         }
    //         else {
    //             if(result.status===601){
    //                 notifyToast(ERRORMDPLOST601)
    //             }
    //             else
    //             {
    //                 notifyToast(ERROR500)
    //             }
    //         }
    //     })
    // }
    // /*
    //
    // */

    return (
        <>
            {/*<Toast/>*/}
            {/*<div className='container' style={{ width : '80%' }}>*/}
            {/*    <form onSubmit={handleSubmit} className='d-flex flex-column '>*/}
            {/*        <div className="form-group">*/}
            {/*            <label htmlFor='exampleInputEmail1' className="form-label mt-4">{Log1}</label>*/}
            {/*            <input value={form.mail} onChange={handleChange} name="mail" type="email" className="form-control" placeholder={Log2} />*/}
            {/*        </div>*/}
            {/*        <div className="form-group">*/}
            {/*            <label htmlFor="exampleInputPassword1" className="form-label mt-4">{Log3}</label>*/}
            {/*            <input value={form.password} onChange={handleChange} name="password" type="password" className="form-control" id="exampleInputPassword1" placeholder="Mot de passe" />*/}
            {/*        </div>*/}
            {/*        <div style={{ display : 'flex' , justifyContent : 'space-between'}} className="my-3">*/}
            {/*            <button style={{ width : '47%'}} onClick={evtnavigate} className='btn btn-primary align-self-end mt-3' type='button'>{Header8}</button>*/}
            {/*            <button style={{ width : '47%'}} className='btn btn-success align-self-end mt-3' type='submit'>{Log4}</button>*/}
            {/*        </div>*/}

            {/*        <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target='#recover'>*/}
            {/*            {So11}*/}
            {/*        </button>*/}
            {/*    </form>*/}
            {/*    <div className="modal fade" id="recover" tabIndex="-1" aria-hidden="true">*/}
            {/*        <div className="modal-dialog">*/}
            {/*            <div className="modal-content">*/}
            {/*                <div className="modal-header">*/}
            {/*                    <h4 className="modal-title">{So11}</h4>*/}
            {/*                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
            {/*                </div>*/}
            {/*                <div className="modal-body">*/}
            {/*                    <h5 className="modal-title">{So15}</h5>*/}
            {/*                    <form onSubmit={handleSecondSubmit} className='d-flex flex-column'>*/}
            {/*                        <div className="form-group">*/}
            {/*                            <label htmlFor='recoverinput' className="form-label mt-4">{So3}</label>*/}
            {/*                            <input value={secondform.mail} onChange={handleSecondChange} name="mail" type="text" className="form-control" id="recoverinput" placeholder={So13} />*/}
            {/*                        </div>*/}
            {/*                        <button className='btn btn-primary align-self-end mt-3' type='submit' >{So14}</button>*/}
            {/*                    </form>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>)
}