import React, {useState} from "react";
import ReactDOM from "react-dom";
import Header from "../components/header";
import {Job,JobForm} from "../components/job";
import {Cta,CtaForm} from "../components/cta";
import {Team,TeamForm} from "../components/team";
import {Gallery,GalleryForm} from "../components/gallery";
import {Contact,ContactForm} from "../components/contact";
import {Footer,FooterForm} from "../components/footer";
import '../assets/style/contentconf.css'
import {Hero,HeroForm} from "../components/hero";
import {AboutForm,About} from "../components/about";

export default function ContentConf(){
    // fetch data
    //switch case
    // case="exemple" -> create setState ->add component leftcol et right col




    //init value with data from api
    const [header,setHeader]=useState({
        id:0,
        label:'header',
        url:'img/logo.svg',
        alt:"logo",
        lis:[
            {id:0,href:"#hero",item:"Home"},
            {id:1,href:"#about",item:"À propos de nous"},
            {id:2,href:"#job",item:"Agenda"},
            {id:3,href:"#shows",item:"À l'affiche"},
            {id:4,href:"#team",item:"La belle équipe"},
            {id:5,href:"#gallery",item:"Galerie"},
            {id:6,href:"#contact",item:"Contact"}
        ]})
    const [hero,setHero]=useState({
        id: 1,
        label: 'hero',
        title:"Lorem ipsum dolor sit amet.",
        subtitle:"Cras sed sollicitudin lorem, ut.",
        btn1url:"/",
        btn1value:"Praesent",
        btn2url:"/",
        btn2value:"dapibus",
        backgroundurl:"/img/hero1.jpg",
        colortitle:"#fff",
        colorsubtitle:"#aaa8a6",
        fontsizetitle:"58px",
        fontsizesubtitle:"34px",
        })

    const [about,setAbout]=useState({
        id: 3,
        label: 'about',
        title:"À propos de nous",
        lis:
            [
                {content:"Donec et dolor id magna blandit venenatis. Nam non ipsum felis. Morbi efficitur risus ut nisi euismod, nec finibus nisi ultricies. Vivamus tristique est risus, sit amet maximus nisl malesuada eget. Maecenas faucibus nulla quis lacus mollis, eu auctor dui lobortis. Curabitur euismod lacinia dapibus. Aliquam massa diam, blandit varius aliquam vitae, finibus ut enim. Ut rhoncus venenatis justo eget blandit. Vivamus vel enim aliquam, iaculis est in, lobortis enim. Pellentesque at neque felis. Maecenas placerat, dolor porta mollis gravida, augue velit tincidunt erat, quis dictum enim libero nec magna. Fusce nec turpis blandit, hendrerit enim at, dignissim erat. Phasellus efficitur venenatis cursus. Sed leo sem, dictum eget nulla vel, fermentum posuere elit. Cras at orci iaculis erat varius porta at in nunc.",content1:"Nullam vestibulum magna in",content2:"Nullam vestibulum magna in"},
                {content:"Proin sed mauris vehicula, fermentum dolor laoreet, lobortis leo. Cras lobortis, ligula non commodo hendrerit, leo ligula vulputate tellus, non dignissim arcu metus in ipsum. Pellentesque fringilla neque diam. Vivamus pellentesque eleifend elit id congue. Aliquam elementum volutpat rhoncus. Aenean dapibus a nisi et vulputate. Proin fringilla condimentum massa sed tincidunt.",content1:"Nullam vestibulum magna in",content2:"Nullam vestibulum magna in"}
            ],
        colortext:"#000000",
        colortitle:"#37517e",
        coloricon:"#37517e"
    })
    const [aboutRow,setAboutRow]=useState(about.lis)
    const [job,setJob]=useState({id: 7,
        label: 'job',
        title:"Agenda",
        subtitle:"nos prochains rendez-vous",
        date1:"01/01/1970",hour1:"14:30",place1:"À domicile",title1:"Magellan",
        date2:"01/01/1970",hour2:"14:30",place2:"Dans le jardin",title2:"Magellan",
        date3:"01/01/1970",hour3:"14:30",place3:"Dans un autre lieu",title3:"Magellan",
        date4:"01/01/1970",hour4:"14:30",place4:"Encore un",title4:"Magellan",
        date5:"01/01/1970",hour8:"14:30",place5:"Là aussi",title5:"Magellan",
    })
    const [cta,setCta]=useState({
        id: 4,
        label: 'cta',
        title:"La belle équipe",
        subtitle:"Une union soudée",
        imgUrl:"/",
        content:"content",
        color1:"#FFFFFF",
        color2:"#000000",
        ctaValue:"cta"
    })
    const [team,setTeam]=useState({
        id: 5,
        label: 'team',
        title:"L'équipe",
        subtitle:"Nullam vestibulum magna in.",
        rows:
            [
                {imgurl:"img/team/benoit.jpg",imgalt:"alt",name:"Nulla non",job:"Praesent at",bio:"Quisque vel odio sit amet lacus lobortis hendrerit. Praesent sit amet hendrerit mauris, non elementum nisi. Donec auctor commodo lorem, id hendrerit libero pretium nec. Pellentesque eget magna ut mi finibus dapibus non a lectus. Vivamus in tincidunt leo. Mauris ac ex nec nunc tempus laoreet. Donec eget turpis in ipsum pellentesque ornare. Vivamus non dictum nisl. Curabitur porttitor feugiat metus, nec scelerisque lectus imperdiet ut. Aliquam quis velit et sapien gravida volutpat lobortis aliquam elit. Vestibulum et vulputate velit. Mauris suscipit quam sit amet faucibus lobortis. Curabitur ipsum felis, fermentum sed eros vel, porttitor vulputate quam. Duis placerat pulvinar est eget ornare. Morbi lacinia enim sed mauris pellentesque, eu pulvinar neque vulputate."},
                {imgurl:"img/team/marion.jpg",imgalt:"alt",name:"Sed tristique",job:"ullamcorper justo",bio:"Praesent sagittis massa fringilla nibh vehicula ornare. Vestibulum ante ipsum, efficitur a sagittis eget, bibendum sed leo. Praesent nec ex dolor. Praesent vitae efficitur nisl. In imperdiet nisl eu bibendum pulvinar. Nunc et nibh blandit, euismod lorem sed, maximus velit. Nulla nec odio suscipit velit fermentum iaculis a sed eros."}
            ]})
    const [gallery,setGallery]=useState({
        id: 6,
        label: 'gallery',
        title:"Galerie",
        content:"Cliquez sur les photos pour plus d'informations",
        rows:
            [
                {imgurl:"img/team/marion.jpg",imgalt:"alt",title:"un homme",legende:"blabla"},
                {imgurl:"img/job.jpg",imgalt:"alt",title:"deux homme",legende:"blabla"},
                {imgurl:"img/hero1.jpg",imgalt:"alt",title:"trois homme",legende:"blabla"},
                {imgurl:"img/team/benoit.jpg",imgalt:"alt",title:"quatre homme",legende:"blabla"},
                {imgurl:"img/hero1.jpg",imgalt:"alt",title:"cinq homme",legende:"blabla"},
                {imgurl:"img/cta.jpg",imgalt:"alt",title:"six homme",legende:"blabla"},
            ]})
    const [contact,setContact]=useState({id:10,
        label: 'contact',
        title:'Contact',
        subtitle:'Pour pouvoir communiquer avec nous directement',
        titleaddress:"Adresse : ",
        address:'313 rue A. Danhauser',
        titleemail:'Email : ',
        email:'contact@contact.co',
        titlephone:'Téléphone : ',
        phone:'0606060606',
        namelabel:'Votre nom : ',
        nameplaceholder:'votre nom ici',
        emaillabel:'Votre email : ',
        emailplaceholder:'Pour pouvoir vous recontacter',
        subjectlabel:'L\'objet de votre message : ',
        subjectplaceholder:'objet',
        messagelabel:'Votre message',
        messageplaceholder:'',
        errormessage:'error',
        correctmessage:'correct',
        btnvalue:'Envoyer'})
    const [footer,setFooter]=useState({id:11,
        label:'footer',
        copyright:'Les Arts Phocéens'})
    const [shows,setShows]=useState({
        id:12,
        label:'shows',
        title:"À l'affiche",
        subtitle:"Nos spectacles",
        rows:[
            {
                imgurl:"/img/cta.jpg",
                imgalt:"spectacle1",
                title:"spectacle1",
                subtitle:"Ecrit par: Un inconnu",
                summary:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor blandit eros eget tempor. Donec ut velit dictum, dictum turpis nec, luctus ex. Phasellus a mi enim. Proin faucibus venenatis erat sit amet finibus. Donec non nunc mauris. Quisque a enim sed elit porttitor egestas. Proin molestie commodo massa, volutpat viverra dui condimentum vitae.\n" +
                    "\n" +
                    "Suspendisse porttitor tempus suscipit. Nulla tellus leo, euismod eu nulla venenatis, pellentesque ultrices lectus. Duis mattis hendrerit libero. Vivamus pellentesque, ipsum vel ultricies ultricies, felis ante rutrum metus, varius scelerisque est enim eu quam. Pellentesque sem orci, suscipit et nisl ac, laoreet faucibus tortor. Sed faucibus diam vel tortor consequat, eu consectetur eros ultrices. Vivamus ornare pretium sem sed vulputate. Aliquam sollicitudin cursus dapibus. Fusce aliquam nulla at risus gravida imperdiet. In ac euismod velit, a eleifend orci. Vivamus convallis commodo purus. Integer a diam dictum, interdum nibh sed, aliquet massa. Mauris sagittis porttitor blandit. Fusce sit amet consequat ligula."
            },
            {
                imgurl:"/img/cta.jpg",
                imgalt:"spectacle1",
                title:"spectacle1",
                subtitle:"Ecrit par: Un inconnu",
                summary:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porttitor blandit eros eget tempor. Donec ut velit dictum, dictum turpis nec, luctus ex. Phasellus a mi enim. Proin faucibus venenatis erat sit amet finibus. Donec non nunc mauris. Quisque a enim sed elit porttitor egestas. Proin molestie commodo massa, volutpat viverra dui condimentum vitae.\n" +
                    "\n" +
                    "Suspendisse porttitor tempus suscipit. Nulla tellus leo, euismod eu nulla venenatis, pellentesque ultrices lectus. Duis mattis hendrerit libero. Vivamus pellentesque, ipsum vel ultricies ultricies, felis ante rutrum metus, varius scelerisque est enim eu quam. Pellentesque sem orci, suscipit et nisl ac, laoreet faucibus tortor. Sed faucibus diam vel tortor consequat, eu consectetur eros ultrices. Vivamus ornare pretium sem sed vulputate. Aliquam sollicitudin cursus dapibus. Fusce aliquam nulla at risus gravida imperdiet. In ac euismod velit, a eleifend orci. Vivamus convallis commodo purus. Integer a diam dictum, interdum nibh sed, aliquet massa. Mauris sagittis porttitor blandit. Fusce sit amet consequat ligula."
            }
        ]
    })
    return(
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-content-center">
                    <div className="col-lg-3">
                        {HeroForm(hero,setHero)}
                    </div>
                    <div className="col-lg-9">
                        {
                        Hero(hero)
                    }
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-content-center">
                    <div className="col-lg-3">
                        {AboutForm(about,setAbout,aboutRow,setAboutRow)}
                    </div>
                    <div className="col-lg-9">{
                        About(about,aboutRow)
                    }
                    </div>
                </div>
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {JobForm(job,setJob)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Job(job)*/}
                {/*    }</div>*/}
                {/*</div>*/}
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {CtaForm(cta,setCta)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Cta(cta)*/}
                {/*    }</div></div>*/}
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {TeamForm(team,setTeam)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Team(team)*/}
                {/*    }*/}
                {/*    </div></div>*/}
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {GalleryForm(gallery,setGallery)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Gallery(gallery)*/}
                {/*    }*/}
                {/*    </div></div>*/}
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {ContactForm(contact,setContact)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Contact(contact)*/}
                {/*    }</div></div>*/}
                {/*<div className="row d-flex justify-content-center align-content-center">*/}
                {/*    <div className="col-lg-3">*/}
                {/*        {FooterForm(footer,setFooter)}*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-9">{*/}
                {/*        Footer(footer)*/}
                {/*    }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
    )
}