import React from "react";
import {insecureGet} from "../service/getDataService";
import {Image} from "react-bootstrap";
import "../assets/style/team.css"
const picture = require("../assets/img/team/benoit.jpg")

export function TeamForm(team,setTeam){}
export function Team(content){
        return (
                <section id="team" className="team section-bg">
                    <div className="container">
                        <div className="row">
                            {
                                content.rows.map(row=> {
                                    let bgimg="url(\""+row.imgurl+"\")"
                                    return (
                                        <div className="col-lg-6 mt-4">
                                            <div className='row member'>
                                            <div className=" col-lg-5 d-flex align-items-start">
                                                <div className="pic" style={{backgroundImage:bgimg}}>
                                                    {/*<Image*/}
                                                    {/*    src={row.imgurl}*/}
                                                    {/*    className="img-fluid team-img"*/}
                                                    {/*    alt={row.imgalt}/>*/}
                                                </div>
                                            </div>
                                                <div className=" col-lg-7 member-info">
                                                    <h4>{row.name}</h4>
                                                    <span>{row.job}</span>
                                                    <p>{row.bio}</p>
                                                </div>

                                        </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
    )
}