import React, {useState} from "react";
import {insecureGet} from "../service/getDataService";
import {Button, Form, FormControl, FormGroup, FormLabel, Image} from "react-bootstrap";
import 'boxicons'
import {Link} from "react-router-dom";
import '../assets/style/contact.css'

export function ContactForm(Contact,setContact){}
export  function Contact(content) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
        return (
                <section id="contact" className="contact section-bg">
                    <div className="container">

                        <div className="section-title">
                            <h2>{content.title}</h2>
                            <p>{content.subtitle}</p>
                        </div>

                        <div className="row d-flex justify-content-center">

                            <div className="col-lg-5 d-flex align-items-stretch">
                                <div className="info">
                                    <div className="address">
                                        <i><box-icon id="boxicon" type='solid' name='buildings'></box-icon></i>
                                        <h4>{content.titleaddress}</h4>
                                        <p>{content.address}</p>
                                    </div>

                                    <div className="email">
                                        <i>
                                            <box-icon name='envelope'></box-icon>
                                        </i>
                                        <h4>{content.titleemail}</h4>
                                        <p>{content.email}</p>
                                    </div>

                                    <div className="phone">
                                        <i>
                                            <box-icon type='solid' name='phone'></box-icon>
                                        </i>
                                        <h4>{content.titlephone}</h4>
                                        <p>{content.phone}</p>
                                    </div>
                                    <div className='row d-flex justify-content-center align-content-center'>
                                        <div className="col-lg-1 insta">
                                            <Link to="https://www.instagram.com/lesartsphoceens/" target="_blank"><i><box-icon type='logo' name='instagram-alt'> </box-icon></i></Link>
                                        </div>
                                        <div className="col-lg-1 fb">
                                            <Link to="https://www.facebook.com/lesartsphoceens" target="_blank"><i><box-icon name='facebook-circle' type='logo'></box-icon></i></Link>
                                        </div>
                                        {/*<div className="col-lg-1 mailto">*/}
                                        {/*    <i><box-icon name='envelope'></box-icon></i>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-5 d-flex justify-content-center align-items-center">
                                {/*<Form noValidate validated={validated} onSubmit={handleSubmit}>*/}
                                {/*    <div className="row">*/}
                                {/*        <FormGroup className="col-md-6" controlId="name">*/}
                                {/*            <FormLabel>{content.namelabel}</FormLabel>*/}
                                {/*            <FormControl type="text" id="name" placeholder={content.nameplaceholder} required/>*/}
                                {/*            <Form.Control.Feedback type="invalid">*/}
                                {/*                Veuillez indiquer votre nom.*/}
                                {/*            </Form.Control.Feedback>*/}
                                {/*        </FormGroup>*/}
                                {/*        <FormGroup className="col-md-6" controlId="email">*/}
                                {/*            <FormLabel>{content.emaillabel}</FormLabel>*/}
                                {/*            <FormControl type="email" id="email" placeholder={content.emailplaceholder} required/>*/}
                                {/*            <Form.Control.Feedback type="invalid">*/}
                                {/*                Veuillez mettre un email valide.*/}
                                {/*            </Form.Control.Feedback>*/}
                                {/*        </FormGroup>*/}
                                {/*    </div>*/}
                                {/*    <FormGroup className="">*/}
                                {/*        <FormLabel>{content.subjectlabel}</FormLabel>*/}
                                {/*        <FormControl type="text" id="subject" placeholder={content.subjectplaceholder} required/>*/}
                                {/*        <Form.Control.Feedback type="invalid">*/}
                                {/*            Vous ne pouvez pas envoyer un objet vide.*/}
                                {/*        </Form.Control.Feedback>*/}
                                {/*    </FormGroup>*/}
                                {/*    <FormGroup>*/}
                                {/*        <FormLabel>{content.messagelabel}</FormLabel>*/}
                                {/*        <FormControl as="textarea" rows="10" placeholder={content.messageplaceholder} required/>*/}
                                {/*        <Form.Control.Feedback type="invalid">*/}
                                {/*            Vous ne pouvez pas envoyer un message vide.*/}
                                {/*        </Form.Control.Feedback>*/}
                                {/*    </FormGroup>*/}
                                {/*    <div className="text-center">*/}
                                {/*        <Button type="submit" variant="outline-primary" >{content.btnvalue}</Button>*/}
                                {/*    </div>*/}
                                {/*</Form>*/}
                            </div>


                        </div>

                    </div>
                </section>
        )
}